<template>
    <div class="bg-main content-main">
      <header class="masthead text-center text-white" style="padding-top: calc(7rem + 72px) !important;">
        <div class="masthead-content">
            <div class="container px-5 nanum-square-bold">
                <h1 class="masthead-heading mb-0" style="font-size: 4.725rem;">스마트 적산의 선두기업</h1>
                <h2 class="masthead-subheading mb-0" style="font-size: 3.225rem;">하우코스트㈜ 건설계약연구소</h2>
                <!-- <a class="btn btn-primary btn-xl rounded-pill mt-5" href="#scroll">Learn More</a> -->
            </div>
        </div>
        <div class="bg-circle-1 bg-circle"></div>
        <div class="bg-circle-2 bg-circle"></div>
        <div class="bg-circle-3 bg-circle"></div>
        <!-- <div class="bg-circle-4 bg-circle"></div> -->
        <div class="bg-circle-5 bg-circle"></div>
      </header>
      
      <section id="scroll" class="my-3" style=" text-align: center; ">
        <div class="" style="padding: 2rem 0;">
          <div style="max-width: 80%; margin: 0 auto;">
            <img class="img-fluid" style="border-radius: 6px;" :src="$imgUrl + 'main_banner_1.png'" alt="..." />
          </div>
        </div>
      </section>

      <div>
        <section id="develIntro" key="develIntro" class="py-4">
          <div class="container px-5">
              <div class="row gx-5 align-items-center">
                  <div class="col-lg-6 order-lg-2">
                      <div class="p-4" style="text-align: right;"><img class="img-fluid rounded-circle" src="../../../public/images/title_img_1.png" alt="..." /></div>
                  </div>
                  <div class="col-lg-6 order-lg-1">
                      <div class="p-4">
                          <h3 class="display-4 nanum-square-bold">적산 소프트웨어 개발</h3>
                          <p class="fc-w noto-sans" style="font-size: medium;">실제 적산 전문가와 설계하여 개발하였고, 매월 최신 자원을 지속적으로 업데이트 하고 있으며 Xcost 프로그램 또한 사용자의 피드백을 상시 접수하여 업데이트를 진행하고 있습니다. 이젠 Xcost로 스마트하고 빠르고 정확하게  적산을 해결해보세요.</p>
                          <a class="mb-0 noto-sans" style="color: #19d64c; font-size: 12pt;" href="#/introduction"><b-icon icon="arrow-down-circle"/> 바로 시작하기</a>
                      </div>
                  </div>
              </div>
          </div>
        </section>
  
        <section id="estimationIntro" key="estimationIntro">
          <div class="container px-5">
              <div class="row gx-5 align-items-center">
                  <div class="col-lg-6">
                      <div class="p-4"><img class="img-fluid rounded-circle" style="filter: brightness(0.95);" src="../../../public/images/title_img_2.jpg" alt="..." /></div>
                  </div>
                  <div class="col-lg-6">
                      <div class="p-4">
                          <h2 class="display-4 nanum-square-bold">건축적산 사무소 운영</h2>
                          <p class="fc-w mb-0 noto-sans" style="font-size: medium;">Xcost의 독자적인 기술력을 이용하여 빠르고 정확하며, 최신 자원을 활용한 최적화된 적산을 진행해드립니다. 하우코스트의 전문적산을 위한 임직원이 항시 대기중 입니다. 적산업체 찾고 계신다면 고민하지마시고 언제든지 저희 회사로 문의해주세요.</p>
                          <div class="mb-0 noto-sans display-base">
                            <p class="" style="color: #19a2d6; font-size: 12pt;"><b-icon icon="telephone-fill"/> 031-417-0466</p>
                            <p class="ml-4" style="color: #19a2d6; font-size: 12pt;"><b-icon class="mr-1" icon="mailbox2"/>
                              <a href="mailto:estimate@howcost.co.kr" style="color: #19a2d6; font-size: 12pt;">estimate@howcost.co.kr</a>
                            </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </section>
  
        <!-- selection 마지막 부분 -->
        <section id="communityIntro" key="communityIntro" class="py-4">
          <div class="container px-5">
              <div class="row gx-5 align-items-center">
                  <div class="col-lg-6 order-lg-2">
                      <div class="p-4" style="text-align: right;"><img class="img-fluid rounded-circle" src="../../../public/images/title_img_3.png" alt="..." /></div>
                  </div>
                  <div class="col-lg-6 order-lg-1">
                      <div class="p-4">
                          <h2 class="display-4 nanum-square-bold">커뮤니티 사이트 운영</h2>
                          <p class="fc-w noto-sans" style="font-size: medium;">프로그램 및 적산 관련 문의사항은 전문적산팀과 개발자가 직접 답변하고, Xcost의 사용자와 지식을 공유하고 소통할 수 있는 커뮤니티 사이트를 운영 중 입니다. Xcost 사용자가 되시면 여러 적산 분야의 전문가들과 소통할 수 있습니다.</p>
                          <a class="mb-0 noto-sans" style="color: #d6b819; font-size: 12pt;" href="http://xcost.me/"><b-icon icon="arrow-down-right-square"/> 커뮤니티 이동</a>
                      </div>
                  </div>
              </div>
          </div>
        </section>
      </div>

    </div>
</template>

<script>
import { apiCall } from '../../common/utils';

export default {
  name: 'Main',
  data(){
    return {
    }
  },
  mounted() {

  },
  created() {

  },
  methods: {
  }
}
</script>

<style>
</style>
